import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import  { MessageCardCrate, MessageCardDiscover, MessageCardLearn } from "../card";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { useVisibleChatBox } from "../../contexts/VisibleChatBoxContext";

const Home: React.FC = () => {
  const { updateVisibility } = useVisibleChatBox();
  
  const handleNewChat = () => {
    updateVisibility(true);
  };
  return (
    <Container>
      <Row>
        <Col>
          <div className="container-fluid my-5">
            <button
              className=" btn btn-primary p-2 new-chat-btn"
              onClick={handleNewChat}
            >
              <IoChatbubbleEllipses /> New Chat
            </button>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="p-4" >
            <h3 className="text-muted">Namaste</h3>
            <h1 className="mb-5">How can I help you today?</h1>
            <div className="d-flex gap-5" style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
              <MessageCardCrate title="CREATE" />
              <MessageCardLearn title="LEARN" />
              <MessageCardDiscover title="DISCOVER" />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
