export const msg ='submit'

export const MESSAGES = {
    error: {
        endpointError: 'Something went Wrong, Please Try again after Sometime.',
    }
};

export const RESPONSE_TYPE = {
    failure: 'Failure',
    success: 'Success',
};

export const PRISMBERRY_WEBSITE_URL ='https://www.prismberry.com/';

export const TAXSPANNER_WEBSITE_URL ='https://taxspanner.com/';

export const MESSAGE_ITEM = [
    {
        "answer_id": 1,
        "conversation_id": 1,
        "user_id": 1,
        "answer": "hi how may i help you",
        "is_read": 0,
        "created_at": "2024-04-05 05:43:54",
        "read_at": null
    },
    {
        "answer_id": 2,
        "conversation_id": 1,
        "user_id": 1,
        "answer": "I am good",
        "is_read": 0,
        "created_at": "2024-04-06 06:17:53",
        "read_at": null
    },
    {
        "answer_id": 3,
        "conversation_id": 1,
        "user_id": 1,
        "answer": "I am good",
        "is_read": 0,
        "created_at": "2024-04-05 06:17:53",
        "read_at": null
    },
  
];

export const TOAST_DIALOG_VARIANT_TYPES = {
    success: 'success',
    danger: 'danger',
    warning: 'warning',
};