import { urlRegex } from "../../utils/regex";
import "./answer.css";
import React from 'react';

function Answer(props: any) {
  const { answer, createdAt } = props;
  const linkify = (text:string) => {
  return text.replace(urlRegex, (url) => {
    const prefixedUrl = url.startsWith('www.') ? `http://${url}` : url;
    const encodedUrl = encodeURI(prefixedUrl);
    console.log(encodedUrl,"encodedUrl")
    return `<a href="${encodeURI(encodedUrl)}" target="_blank">Click Here</a>`;
  });
};  

  const formattedAnswer = linkify(answer.replace(/\n/g, "<br>"));
  console.log(formattedAnswer,"formattedAnswer")
  const formattedCreatedAt = new Date(
    new Date(createdAt).getTime() + 5 * 3600000 + 30 * 60000).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });

  return (
    <div className="container d-flex justify-content-start my-2">
      <div>
        <div className="answer">
          <div
            className="answer-text py-1 px-3 rounded text-shadow-sm"
            dangerouslySetInnerHTML={{ __html: formattedAnswer }}
          />
        </div>
        <div className="d-flex justify-content-start">
          <p className="small text-muted">{formattedCreatedAt}</p>
        </div>
      </div>
    </div>
  );
}

export default Answer;
