import "./question.css";
import React from 'react';

const Question = (props: any) => {
  const {question, createdAt} = props;

  return (
    <div className="container d-flex justify-content-end my-2">
      <div>
        <div className="question">
          <div className="question-text py-1 px-3 rounded ">{question}</div>
        </div>
        <div className="d-flex justify-content-end">
            <p className="small text-muted">{createdAt}</p>
         </div>
      </div>
    </div>
  );
};
export default Question;
