// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  position: relative;
}

.prism-chat-box {
  position: fixed;
  right: 30px;
  bottom: 0px;
}

.prism-chat-box iframe {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/layout.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".home {\n  position: relative;\n}\n\n.prism-chat-box {\n  position: fixed;\n  right: 30px;\n  bottom: 0px;\n}\n\n.prism-chat-box iframe {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
