import React, { useEffect } from 'react';

import Home from "../components/home";
import Navbar from "../components/navbar";

import "./layout.css";

const Layout = () => {
  const adjustIframe = (event: MessageEvent) => {
    if (event.data.action === 'adjustIframe') {
      const iframe = document.querySelector('.prism-chat-box iframe') as HTMLElement | null;
      if (iframe) {
        iframe.style.height = event.data.height;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', adjustIframe);
    return () => window.removeEventListener('message', adjustIframe);
  }, []);

  return (
    <div className="home">
      <Navbar />
      <Home />
      <div className="prism-chat-box">
      <iframe
            title="External Content"
            src="https://taxbot.prismberry.com/chatBot"
            width="400px"
            height="550px"
      />
      </div>
    </div>
  );
};

export default Layout;
