import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { Row, Col} from "react-bootstrap";
import Skeleton from "@mui/material/Skeleton";
import { FaArrowUp, FaRegPaperPlane } from "react-icons/fa6";
import { Link } from "react-router-dom";

import {
  MessageForm,
  MessageInitialsValues,
} from "../../utils/types/formvalues";
import { MessageSchema } from "./schema";
import { getMessages, sendUserMessage } from "../../services/card";
import Question from "../question";
import Answer from "../answer";
import { FatchMessageResponseTypes } from "../../utils/types/common";
import { TAXSPANNER_WEBSITE_URL } from "../../utils/constant";
import ContactForm from "../user-form";
import { useAuthToken } from "../../contexts/AuthToken";
import DisplayMessage from "../display-message";

import TaxSpannerLogo from "../../assets/icons/Taxbot.png";
import prismberry from "../../assets/icons/Prismberry.png";
import textBoat from "../../assets/icons/Taxbot.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";

import "./chatBot.css";

const ChatBot: React.FC = () => {
  const { authToken, setAuthToken, userAuthenticated, setUserAuthenticated } = useAuthToken();
  const [messages, setMessages] = useState<string[]>([]);
  let [answerInResponse, setAnswerInResponse] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [chatboxVisible, setChatboxVisible] = useState(true)
  const [answerResponse, setAnswerResponse] = useState<
    FatchMessageResponseTypes[]
  >([]);
  const ulRef = useRef<HTMLUListElement>(null);
  const [num, setNum] = useState(0);
  const [myState, setState] = useState(true);

  const handleSubmit = async (
    values: MessageForm,
    { resetForm }: FormikHelpers<MessageForm>
  ) => {
    setMessages([...messages, values.Message]);
    setNum(num + 1);
    setLoading(true);
    resetForm();

    if (authToken) {
      try {
        await sendMessage(values);
      } catch (error) {
        console.error(error);
        if (error instanceof Error && error.message === 'Authentication failed. Please log in again.') {
          setAuthToken(null);
          setUserAuthenticated(false)
        }
      }
    }
  };

  const sendMessage = async (values: MessageForm) => {
    try {
      const response = await sendUserMessage(values, authToken);
      const sessionId = response?.session_id;
      localStorage.setItem("sessionId", sessionId);
      fetchMessages();
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  };

  const fetchMessages = async () => {
    let sessionId = localStorage.getItem("sessionId");
    try {
      const response = await getMessages(sessionId, authToken);
      if (response.data) {
        const responseData = response.data;
        if (responseData.item && responseData.item.length > answerResponse.length) {
          setAnswerInResponse(responseData.item.length);
          setAnswerResponse(responseData.item);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      if (error instanceof Error && error.message === 'Authentication failed. Please log in again.') {
        setAuthToken(null);
        setUserAuthenticated(false);
        // You might want to show a message to the user here
      }
    }
  };
  
  const questionCreateAt = () => {
    const currentDate = new Date();

    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const responseQuestionCreateAt = (createdAt: string) => {
    const formattedCreatedAt = new Date(
      new Date(createdAt).getTime() + 5 * 3600000 + 30 * 60000).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
    return formattedCreatedAt;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>, formik: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      formik.submitForm();
      // Update the state to false
      setState(false);
      localStorage.setItem('myState', JSON.stringify(false));
    }
  };

  useEffect(()=>{
   
    let res  =  localStorage.getItem("myState")
    if(res!=null){
      res =  JSON.parse(res)
      setState(Boolean(res))
    }
  },[])

  const scrollToBottom = () => {
    if (ulRef.current) {
      ulRef.current.scrollTop = ulRef.current.scrollHeight;
    }
  };

  const toggleConversation = () => {
    setChatboxVisible(!chatboxVisible)
    const header = document.querySelector(".prism-robo-header") as HTMLElement;

    window.parent.postMessage(
      {
        action: "adjustIframe",
        height: chatboxVisible ? "100px" : "600px",
      },
      "*"
    );
  };

  useEffect(() => {
    if (messages && authToken) {
      fetchMessages();
      const intervalId = setInterval(fetchMessages, 10000);
      return () => clearInterval(intervalId);
    }
  }, [messages, answerResponse]);

  useEffect(() => {
    if (userAuthenticated && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setMessages([lastMessage]);
      const messageToSend = { Message: lastMessage };
      sendMessage(messageToSend);
    }
  }, [userAuthenticated]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, answerResponse]);


  const prismsberryWebsite = () => {
    window.open(TAXSPANNER_WEBSITE_URL, "_blank");
  };

  return (
    <>
      <div className="prism-pop d-flex">
        <div className="toggle-button mt-1 me-2">
          {chatboxVisible ? <CrossIcon /> : <FaArrowUp />}
        </div>
        <div className="prism-robo">
          <Row>
            <Col lg={12}>
              <div className="d-flex align-items-center justify-content-between p-1 prism-robo-header">
                <div className="d-flex justify-content-between pointer py-2 px-2" style={{ width: '100%' }} >

                  <div className="d-flex align-items-center">
                    <img src={TaxSpannerLogo} width={45} />
                    <img className="px-2" src={textBoat} width={90} />
                  </div>
                </div>
              </div>
              {chatboxVisible && (
                <ul className="list-unstyled question-container" ref={ulRef}>
                  { myState && authToken  && messages.length <1 &&<li><DisplayMessage/></li>}
                  {!authToken && (
                    <li>
                      <ContactForm />
                    </li>
                  )}
                  {answerResponse.map((item, index) => (
                    <li key={index + 1} className="conversation bg-body-grey">
                      <Question
                        question={item.message}
                        createdAt={responseQuestionCreateAt(item.chat_created_at)}
                      />
                      <Answer
                        answer={item.response}
                        createdAt={item.response_created_at}
                      />
                    </li>
                  ))}
                  {loading
                    ? messages.map((item, index) => (
                      <li key={index + 1}  className="conversation bg-body-grey">
                        {index < answerInResponse ? null : (
                          <>
                            <Question question={item} createdAt={questionCreateAt()}
                            />
                            {authToken ? (<div className="container"><Skeleton variant="text" width={100} /></div>) : (
                              <li>
                                <ContactForm />
                              </li>
                            )}
                          </>
                        )}
                      </li>
                    ))
                    : null}
                </ul>
              )}
            </Col>
          </Row>
          {chatboxVisible && (
            <Row>
              <Col lg={12} className="mb-2">
                <div className="registration-form">
                  <Formik
                    initialValues={MessageInitialsValues}
                    validationSchema={MessageSchema}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form>
                        <div className="form-group row mt-1">
                          <div className="d-flex">
                            <Field
                              as="input"
                              type="text"
                              className="form-control px-2 text-field"
                              id="Message"
                              name="Message"
                              placeholder="Enter Your Message Here"
                              onKeyPress={(e: any) => handleKeyPress(e, formik)}
                              autoComplete="off"
                              style={{
                                outline:'none',
                                fontSize: "16px",
                                border: "none",

                              }}
                              disabled={!authToken}
                            />
                            <button type="submit" disabled={!authToken} className="custom-btn" >
                              <FaRegPaperPlane style={{ fontSize: "20px", }} />
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="d-flex justify-content-center align-items-center powered_by my-2">
                    <span>Powered by</span> <Link to="https://www.prismberry.com/"  target="_blank"><img src={prismberry} /></Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatBot;
