// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question{
  max-width: 400px;
  padding: 3px;
  background-color: #ffffff;
  border-radius: 10px;
}

.question-text {
  overflow-wrap: break-word; 
}`, "",{"version":3,"sources":["webpack://./src/components/display-message/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".question{\n  max-width: 400px;\n  padding: 3px;\n  background-color: #ffffff;\n  border-radius: 10px;\n}\n\n.question-text {\n  overflow-wrap: break-word; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
