import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { validationSchema } from './schema';
import { userRegistration } from '../../services/card';
import {  userRegisterPayloadTypes } from '../../utils/types/common';
import { useAuthToken } from '../../contexts/AuthToken';

import './user-form.css'; 

const ContactForm: React.FC= () => {
  const { setAuthToken, setUserAuthenticated } = useAuthToken();

  const handleSubmit = async(values: any) => {
    const { name, email, phone } = values;
    const valuesToSend: userRegisterPayloadTypes = {
      Name: name,
      Email: email,
      Phone: phone,
    };
    const response = await userRegistration(valuesToSend);
    if (response?.status === 200) {
      const token = response?.data?.access_token;
      setUserAuthenticated(true);
      setAuthToken(token)
    }
  };

  return (
    <div className="container contact-form-container pt-2">
      <div className="row justify-content-center">
        <p className='text-center text-muted'>Please introduce yourself in chat</p>
        <div className="col-sm-10">
          <Formik
            initialValues={{ name: '', email: '', phone: '', termsAccepted: false }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isValid }) => (
              
              <Form className="contact-form form-contact">
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                  placeholder="Name*"
                  style={{
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderRightBottom:2,
                  }}
                />
                <ErrorMessage name="name" component="div" className="invalid-feedback" />

                <Field
                  type="email"
                  id="email"
                  name="email"
                  className={`form-control  mt-3 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                  placeholder="Email"
                  style={{
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                  }}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback" />

                <Field
                  type="tel"
                  name="phone"
                  id="phone"
                  className={`form-control mt-3 ${errors.phone && touched.phone ? 'is-invalid' : ''}`}
                  placeholder="Phone*"
                  style={{
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                  }}
                />
                <ErrorMessage name="phone" component="div" className="invalid-feedback" />

                <div className="form-check mt-3">
                  <Field
                    type="checkbox"
                    name="termsAccepted"
                    id="termsAccepted"
                    className={`form-check-input ${errors.termsAccepted && touched.termsAccepted ? 'is-invalid' : ''}`}
                  />
                  <label className="form-check-label">
                    I accept{' '}the terms and conditions 
                  </label>
                  <ErrorMessage name="termsAccepted" component="div" className="invalid-feedback" />
                </div>
                <button type="submit" className="btn btn-primary btn-block mt-3 form-control" disabled={!isValid}>
                  Send
                </button>
              </Form>
            )
            }
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
