import * as Yup from 'yup';
import { PHONE_NUMBER } from '../../utils/regex';

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email'),
    phone: Yup.string().required('Phone number is required')         
    .matches(PHONE_NUMBER, 'Please enter a valid 10 digit phone number'),
    termsAccepted: Yup.boolean().oneOf([true], 'Please accept the terms and conditions'),
  });
  