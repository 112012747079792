// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.defined-question{
    background-color: aliceblue;
    border-radius: 10px;
    font-size: 18px;
}

.pointer{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/card/card.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".defined-question{\n    background-color: aliceblue;\n    border-radius: 10px;\n    font-size: 18px;\n}\n\n.pointer{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
