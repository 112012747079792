import { useMessageContext } from "../../contexts/MessageContext";
import { useVisibleChatBox } from "../../contexts/VisibleChatBoxContext";
import React from 'react';

import "./card.css";

interface SimpleCardProps {
  title: string;
}

export const MessageCardCrate: React.FC<SimpleCardProps> = ({ title }) => {
  const { addMessage, setNumber, number } = useMessageContext();
  const { updateVisibility } = useVisibleChatBox();

  const handleClick = () => {
    addMessage("How to File ITR?");
    setNumber(number + 1);

    updateVisibility(true);
  };
  const handleClickB = () => {
    addMessage("Register on ITD Portal");
    setNumber(number + 1);

    updateVisibility(true);
  };
  const handleClickC = () => {
    addMessage("What is Tax Regime?");
    setNumber(number + 1);

    updateVisibility(true);
  };
  return (
    <div className="card-container">
      <div
        className="card"
        style={{ width: "20rem", border: "1px solid #ccc" }}
      >
        <div className="card-body">
          <h5 className="card-title fw-bold text-muted">{title}</h5>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClick}
          >
            How to File ITR?
          </div>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClickB}
          >
            Register on ITD Portal
          </div>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClickC}
          >
            What is Tax Regime?
          </div>
        </div>
      </div>
    </div>
  );
};

export const MessageCardLearn: React.FC<SimpleCardProps> = ({ title }) => {
  const { addMessage, setNumber, number } = useMessageContext();
  const { updateVisibility } = useVisibleChatBox();

  const handleClick = () => {
    addMessage("Which ITR Form to Fill out??");
    setNumber(number + 1);

    updateVisibility(true);
  };
  const handleClickB = () => {
    addMessage("Where to invest to save tax?");
    setNumber(number + 1);

    updateVisibility(true);
  };
  const handleClickC = () => {
    addMessage("What are Tax Slabs?");
    setNumber(number + 1);

    updateVisibility(true);
  };
  return (
    <div className="card-container">
      <div
        className="card"
        style={{ width: "20rem", border: "1px solid #ccc" }}
      >
        <div className="card-body">
          <h5 className="card-title fw-bold text-muted">{title}</h5>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClick}
          >
            Which ITR Form to Fill out?
          </div>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClickB}
          >
            Where to invest to save tax?
          </div>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClickC}
          >
            What are Tax Slabs?
          </div>
        </div>
      </div>
    </div>
  );
};

export const MessageCardDiscover: React.FC<SimpleCardProps> = ({ title }) => {
  const { addMessage, setNumber, number } = useMessageContext();
  const { updateVisibility } = useVisibleChatBox();

  const handleClick = () => {
    addMessage("TaxSpanner Tax Optimizer");
    setNumber(number + 1);

    updateVisibility(true);
  };
  const handleClickB = () => {
    addMessage("TaxSpanner Capital Gains");
    setNumber(number + 1);

    updateVisibility(true);
  };
  const handleClickC = () => {
    addMessage("TaxSpanner Audit filing Service");
    setNumber(number + 1);

    updateVisibility(true);
  };
  return (
    <div className="card-container">
      <div
        className="card"
        style={{ width: "20rem", border: "1px solid #ccc" }}
      >
        <div className="card-body">
          <h5 className="card-title fw-bold text-muted">{title}</h5>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClick}
          >
            TaxSpanner Tax Optimizer
          </div>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClickB}
          >
            TaxSpanner Capital Gains
          </div>
          <div
            className="defined-question p-2 m-1 pointer"
            onClick={handleClickC}
          >
            TaxSpanner Audit filing Service
          </div>
        </div>
      </div>
    </div>
  );
};
