import React, { createContext, useContext, useState, ReactNode } from 'react';

interface VisibleChatBoxContextType {
  isVisible: boolean;
  updateVisibility: (newValue: boolean) => void;
}

const VisibleChatBoxContext = createContext<VisibleChatBoxContextType | undefined>(undefined);

export const useVisibleChatBox = () => {
  const context = useContext(VisibleChatBoxContext);
  if (!context) {
    throw new Error('useVisibleChatBox must be used within a VisibleChatBoxProvider');
  }
  return context;
};

interface VisibleChatBoxProviderProps {
  children: ReactNode;
}

export const VisibleChatBoxProvider: React.FC<VisibleChatBoxProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const updateVisibility = (newValue: boolean) => {
    setIsVisible(newValue);
  };

  return (
    <VisibleChatBoxContext.Provider value={{ isVisible, updateVisibility }}>
      {children}
    </VisibleChatBoxContext.Provider>
  );
};
