import TAX_SPANNER_IMAGE from "../../assets/images/taxspannnerImage.png";
import React from 'react';
import './navbar.css'

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-light">
        <div className="container-fluid">
          <img src={`${TAX_SPANNER_IMAGE}`} />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
