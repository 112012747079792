import React, { createContext, useContext, useState, ReactNode } from "react";

interface AuthTokenContextType {
  authToken: string | null;
  userAuthenticated: boolean;
  setAuthToken: (token: string | null) => void;
  setUserAuthenticated: (authenticated: boolean) => void;
}

const AuthTokenContext = createContext<AuthTokenContextType | undefined>(
  undefined
);

export const useAuthToken = () => {
  const context = useContext(AuthTokenContext);
  if (!context) {
    throw new Error("useAuthToken must be used within an AuthTokenProvider");
  }
  return context;
};

export const AuthTokenProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [authToken, setAuthToken] = useState<string | null>(() => {
    return localStorage.getItem("authToken");
  });
  const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);

  const updateAuthToken = (newToken: string | null) => {
    if (newToken) {
      localStorage.setItem("authToken", newToken);
      setAuthToken(newToken);
    } else {
      localStorage.removeItem("authToken");
      setAuthToken(null);
    }
  };

  return (
    <AuthTokenContext.Provider
      value={{ authToken, userAuthenticated, setAuthToken: updateAuthToken, setUserAuthenticated }}
    >
      {children}
    </AuthTokenContext.Provider>
  );
};
