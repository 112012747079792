// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer{
    max-width: 400px;
    padding: 3px;
    background-color: #E9F8FF;
    border-radius: 10px;
}

.answer-text{
    word-wrap: break-word; 
    overflow-wrap: break-word; 
}`, "",{"version":3,"sources":["webpack://./src/components/answer/answer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":[".answer{\n    max-width: 400px;\n    padding: 3px;\n    background-color: #E9F8FF;\n    border-radius: 10px;\n}\n\n.answer-text{\n    word-wrap: break-word; \n    overflow-wrap: break-word; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
