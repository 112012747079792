// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.not-found-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: #333;
}

.not-found-container p {
    font-size: 1.5rem;
    color: #666;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/error404/not-found.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".not-found-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.not-found-container h1 {\n    font-size: 3rem;\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.not-found-container p {\n    font-size: 1.5rem;\n    color: #666;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
