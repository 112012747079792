import React, { createContext, useContext, useState, ReactNode } from 'react';

interface MessageContextType {
    message: string[]; // Change type to string[]
    addMessage: (message: string) => void;
    number: number;
    setNumber: (newNumber: number) => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const useMessageContext = () => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessageContext must be used within a MessageProvider');
    }
    return context;
};

interface MessageProviderProps {
    children: ReactNode;
}

export const MessageProvider: React.FC<MessageProviderProps> = ({ children }) => {
    const [message, setMessage] = useState<string[]>([]); // Initialize as empty array
    const [number, setNumber] = useState<number>(0);

    const addMessage = (message: string) => {
        setMessage((prevMessages) => [...prevMessages, message]);
    };
    
    const contextValue: MessageContextType = {
        message,
        addMessage,
        number,
        setNumber,
    };

    return (
        <MessageContext.Provider value={contextValue}>
            {children}
        </MessageContext.Provider>
    );
};
