// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-contact {
  border-top: 4px solid #06c;
  border-radius: 10px;
  padding-left: 20px;
  padding: 10px 20px 20px 10px ;
}

`, "",{"version":3,"sources":["webpack://./src/components/user-form/user-form.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,kBAAkB;EAClB,6BAA6B;AAC/B","sourcesContent":[".contact-form {\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.form-contact {\n  border-top: 4px solid #06c;\n  border-radius: 10px;\n  padding-left: 20px;\n  padding: 10px 20px 20px 10px ;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
