import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthTokenProvider } from './contexts/AuthToken';

import 'bootstrap/dist/css/bootstrap.min.css';
import { MessageProvider } from './contexts/MessageContext';
import { VisibleChatBoxProvider } from './contexts/VisibleChatBoxContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthTokenProvider>
      <MessageProvider>
        <VisibleChatBoxProvider>
           <App />
      </VisibleChatBoxProvider>
      </MessageProvider>
    </AuthTokenProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
