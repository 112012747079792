export interface UserRegistrationForm {
    Name : string
    Email: string
    Phone: string
}

export const UserRegistrationInitialsValues: UserRegistrationForm = {
    Name : '',
    Email: '',
    Phone:'',
}

export interface MessageForm {
    Message: string
}

export const MessageInitialsValues:MessageForm ={
    Message:'',
}
