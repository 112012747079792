import axios from'axios';

import {  SendMessagePayloadTypes, userRegisterPayloadTypes } from "../utils/types/common"
import { MESSAGES } from '../utils/constant';
import { API_URLS } from '../utils/constant/api-endpoints';

export const userRegistration = async (values: userRegisterPayloadTypes) => {
    try {
        const response = await axios.post(
            API_URLS.USER_REGISTRATION,
            values,
            {
                headers:{
                    'Content-Type': 'application/json',
                    "access-control-allow-origin" : "*",
                }
            }
        );
        if (response)
            return response
        else throw new Error(MESSAGES.error.endpointError)
    } catch (error: any) {
        return error
    }
}

export const sendUserMessage = async (values: SendMessagePayloadTypes, token: string|null) => {
    try {
        const response = await axios.post(
            API_URLS.SEND_QUESTION,
            values,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    "access-control-allow-origin" : "*",
                }
            }
        )
        if (response)
            return response.data
        else throw new Error(MESSAGES.error.endpointError)
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            localStorage.removeItem('authToken');
            throw new Error('Authentication failed. Please log in again.');
        }
        throw Error(error.response.data.message)
    }
}

export const getMessages = async (sessionId:string|null , token: string|null) => {
    const data ={"SessionId":sessionId}
    try {
        const response = await axios.post(
             API_URLS.FATCH_MASSAGES,
             data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    "access-control-allow-origin" : "*",
                },
            }
        )
        return response
    } catch (error: any) {
        if (error.response && error.response.status === 400) {
            localStorage.removeItem('authToken');
            throw new Error('Authentication failed. Please log in again.');
        }
        throw Error(error.response.data.message)
    }
}