import "./index.css";
import React from 'react';

const DisplayMessage = () => {

  return (
    <div className="container d-flex justify-content-start my-2">
      <div>
        <div className="question">
          <div className="question-text py-1 px-3 rounded ">Welcome to TaxSpanner! I'm here to answer any questions you might have on your tax planning and provide support whenever you need it. How can I assist you today?</div>
        </div>
      </div>
    </div>
  );
};
export default DisplayMessage;
