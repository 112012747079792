import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "../pages";
import NotFoundPage from "../components/error404";
import ChatBot from "../components/chat-bot";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/chatBot" element={<ChatBot/>}/>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
